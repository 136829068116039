<template>
  <div class="box-page">
    <syemt v-if="isAdminUser === 'system'" :todayMap="todayMap" :yesterdayMap="yesterdayMap"/>
    <user v-if="isAdminUser === 'web'" :todayMap="todayMap" :yesterdayMap="yesterdayMap"/>
    <div class="filter-box" style="padding-top: 15px">
      <a-form layout="horizontal" ref="formRef" :model="formState">
        <a-row :gutter="24">
          <a-col :span="4" v-if="userType == 'system'">
            <a-form-item label="用户名" name="uid">
              <a-select
                  v-model:value="formState.uid"
                  style="width: 100%"
                  placeholder="请选择用户名"
              >
                <a-select-option
                    :value="item.value"
                    :label="item.label"
                    v-for="item in userOptions"
                    :key="item.value"
                >{{ item.label }}
                </a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <!--          <a-col :span="4">-->
          <!--            <a-form-item label="服务商" name="amountId">-->
          <!--              <a-select-->
          <!--                  v-model:value="formState.amountId"-->
          <!--                  style="width: 100%"-->
          <!--                  placeholder="请选择服务商"-->
          <!--              >-->
          <!--                <a-select-option-->
          <!--                    :value="item.value"-->
          <!--                    :label="item.label"-->
          <!--                    v-for="item in amountOption"-->
          <!--                    :key="item.value"-->
          <!--                >{{ item.label }}-->
          <!--                </a-select-option-->
          <!--                >-->
          <!--              </a-select>-->
          <!--            </a-form-item>-->
          <!--          </a-col>-->
          <a-col :span="4">
            <a-form-item label="识别类型" name="type">
              <a-select
                  v-model:value="formState.type"
                  style="width: 100%"
                  placeholder="请选择识别类型"
              >
                <a-select-option
                    :value="item.value"
                    :label="item.label"
                    v-for="item in typeList"
                    :key="item.value"
                >{{ item.label }}
                </a-select-option
                >
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item label="日期" name="start_End">
              <a-config-provider :locale="zhCN">
                <a-range-picker v-model:value="formState.start_End" :locale="locale"/>
              </a-config-provider>
            </a-form-item>
          </a-col>
          <a-col :span="6">
            <a-form-item>
              <a-button type="primary" @click="handelSearch">
                查询
              </a-button>
              <a-button :style="{ marginLeft: '8px' }" @click="handelReset">
                重置
              </a-button>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div class="table-list" style="padding-top: 15px">
      <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="totalTableData"
          size="mini"
          :row-style="{height:'50px'}"
          :cell-style="{padding:'0px'}"
          tooltip-effect="dark"
          style="width: 100%; overflow: auto"
          :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
      >
        <el-table-column label="汇总" prop="nickName"></el-table-column>
        <el-table-column label="质检条数" prop="recognitionTotal"></el-table-column>
        <el-table-column label="转写时长（小时）" prop="recognitionMinutes">
          <template #default="{row}">
            {{ row.recognitionMinutes ? (row.recognitionMinutes / 3600).toFixed(2) : 0 }}
          </template>
        </el-table-column>
        <el-table-column label="消费金额（元）" prop="recognitionCharge">
          <template #default="{row}">
            {{ row.recognitionCharge ? (row.recognitionCharge).toFixed(4) : 0 }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="table-list" style="padding-top: 15px">
      <el-table
          v-loading="loading"
          ref="multipleTable"
          :data="tableData"
          size="mini"
          :row-style="{height:'50px'}"
          :cell-style="{padding:'0px'}"
          tooltip-effect="dark"
          style="width: 100%; overflow: auto"
          :header-cell-style="{ background: '#e0e5ff', color: '#24252F' }"
      >
        <el-table-column label="日期" prop="date"></el-table-column>
        <el-table-column label="用户名" prop="nickName" v-if="isAdminUser === 'system'"></el-table-column>
        <el-table-column label="质检条数" prop="recognitionTotal">
        </el-table-column>
        <el-table-column label="转写时长(小时）" prop="recognitionMinutes">
          <template #default="{row}">
            {{ row.recognitionMinutes ? (row.recognitionMinutes / 3600).toFixed(2) : 0 }}
          </template>
        </el-table-column>
        <el-table-column label="消费金额（元）" prop="recognitionCharge">
          <template #default="{row}">
            {{ row.recognitionCharge ? (row.recognitionCharge).toFixed(4) : 0 }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pager" style="padding-top: 8px">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageInfo.currentPage"
          :page-size="pageInfo.pageSize"
          background :page-sizes="[10, 20, 30, 40]" layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.total">
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {reactive} from 'vue';
import _aSRabilitys from "@/api/open/ASRabilitys";
import {PageModule} from '../common/pagination';
import moment from 'moment';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import syemt from "@/views/open/ASRabilitys/component/syemt.vue";
import user from "@/views/open/ASRabilitys/component/user.vue";
import _lineService from "@/api/open/LineQualityInspections";

const date = new Date()
// const date1 = new Date(date.getTime() - (24 * 3600 * 1000 * 7))
const year = date.getFullYear()
const n = date.getMonth() + 1
const d = date.getDate()
const str = `${year}-${n > 9 ? n : '0' + n}-${d > 9 ? d : '0' + d}`
// const year1 = date1.getFullYear()
// const n1 = date1.getMonth() + 1
// const d1 = date1.getDate()
// const str1 = `${year1}-${n1 > 9 ? n1 : '0' + n1}-${d1 > 9 ? d1 : '0' + d1}`

/* 
  createTime 创建时间 updateTime 修改时间 id 主键
  uid 用户id interfaceId 接口id amountId 服务id
  checkTotal 质检总数 checkSuccess 质检成功数 checkCharge 费用统计
  hitTypeCount 类型命中统计(命中统计展示) hitRuleCount 规则命中统计
  minutesCount 分钟统计(分钟,需要除以60分钟换为小时展示) date 日期
  recognitionTotal ？ recognitionSuccess ？ recognitionMinutes ？
  nickName 用户名称 amountName 服务商名称 userPrice 用户成本价格
*/
class TableModule {
  constructor(item) {
    this.createTime = item.createTime
    this.updateTime = item.updateTime
    this.id = item.id
    this.uid = item.uid
    this.interfaceId = item.interfaceId
    this.amountId = item.amountId
    this.checkTotal = item.checkTotal
    this.checkSuccess = item.checkSuccess
    this.checkCharge = item.checkCharge
    this.hitTypeCount = item.hitTypeCount
    this.hitRuleCount = item.hitRuleCount
    this.minutesCount = item.minutesCount
    this.date = item.date
    this.recognitionCharge = item.recognitionCharge
    this.recognitionTotal = item.recognitionTotal
    this.recognitionSuccess = item.recognitionSuccess
    this.recognitionMinutes = item.recognitionMinutes
    this.nickName = item.nickName
    this.amountName = item.amountName
    this.userPrice = item.userPrice
  }
}

/*
  "dateEnd":"2022-07-29",  # 结束时间(必传)
  "dateBegin":"2022-07-01", # 开始时间(必传)
  "uid":100008,             # 用户id
  "amountId":"9",           # 服务商id
  "sortBy":["check_total"],  # 排序字段
  "sortDesc":[false]         # 排序规则
*/
class FormModule {
  constructor() {
    this.start_End = [moment(str, 'YYYY-MM-DD 00:00:00'), moment(str, 'YYYY-MM-DD 23:59:59')]
    this.uid = undefined
    this.amountId = undefined
    this.type = 1
  }

  getParamsJson() {
    return {
      dateEnd: this.start_End[1].format('YYYY-MM-DD'),
      dateBegin: this.start_End[0].format('YYYY-MM-DD'),
      type: this.type,
      uid: this.uid ? '' + this.uid : ''
    }
  }
}

export default {
  components: {
    syemt,
    user,
  },
  props: {
    isAdminUser: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      typeList: [
        {
          value: 0,
          label: "一句话识别"
        },
        {
          value: 1,
          label: "录音文件转写"
        }
      ],
      userType: this.$store.state.userType,
      tableData: [],
      totalTableData: [],
      loading: false,
      formState: reactive(new FormModule()),
      todayMap: new TodayMap(),
      yesterdayMap: new YesterdayMap(),
      pageInfo: new PageModule(),
      zhCN: zhCN,
      amountOption: [],
      userOptions: [],
    };
  },
  created() {
    this.getPrice()
    this.getCheckCount()
    this.getTotalCount()
    this.getAmountList()
    this.getUserOptions()
  },
  computed: {
    getUser() {
      return this.$store.getters.user
    }
  },
  methods: {
    async getAmountList() {
      const res = await _lineService._recordService.getAmountList()
      this.amountOption = res?.data?.map(item => {
        return {label: item.name, value: item.id}
      }) || [];
    },
    async getUserOptions() {
      const res = await _lineService._recordService.queryList()
      this.userOptions = res.data.map(item => {
        return {label: item.nickname, value: item.uid}
      })
      console.log(res)
    },
    async getTotalCount() {
      const res = await _aSRabilitys._statisticsService.getTotal()
      console.log(res)
      this.todayMap.setTodayMap(res.todayMap)
      this.yesterdayMap.setYesterdayMap(res.yesterdayMap, res.todayMap)
    },
    async getCheckCount() {
      const res = await _aSRabilitys._statisticsService.getList(this.getparams())
      const obj = new TodayMap()
      obj.setTodayMap2(res.count)
      this.totalTableData = [obj];
      this.tableData = res.page.records.map(item => new TableModule(item))
      this.pageInfo.setTotal(res.page.total)
    },
    async getPrice() {
      const res = await _aSRabilitys._statisticsService.getPrice({
        uid: this.getUser.uid
      })
      this.todayMap?.setApiPrice(res.data?.apiPrice || 0)
      this.todayMap?.setAsrPrice(res.data?.asrPrice || 0)
      // console.log(res.data)
    },
    getparams() {
      return {
        ...this.pageInfo.getParamsJson(),
        ...this.formState.getParamsJson()
      }
    },
    handelSearch() {
      this.pageInfo.init()
      this.getCheckCount()
    },
    handleSizeChange(val) {
      this.pageInfo.setPageSize(val)
      this.getCheckCount()
    },
    handleCurrentChange(val) {
      this.pageInfo.setCurrentPage(val)
      this.getCheckCount()
    },
    handelReset() {
      this.$refs.formRef.resetFields()
    }
  },
};

/*
  语音质检今日消费 fileRecognitionCharge
  今日质检时长(小时) fileRecognitionMinutes
  语音质检成本价(元/小时) apiPrice
  一句话质检成本价(元/小时) asrPrice
  今日消费 phraseRecognitionCharge
  今日质检次数 phraseUidCount
*/
class TodayMap {
  constructor(item) {
    this.fileRecognitionCharge = 0
    this.fileRecognitionMinutes = 0
    this.apiPrice = 0
    this.asrPrice = 0
    this.phraseRecognitionCharge = 0
    this.phraseUidCount = 0
    this.fileUidCount = 0
    this.recognitionCharge = 0
    this.recognitionMinutes = 0
    this.recognitionSuccess = 0
    this.recognitionTotal = 0
    this.uidCount = 0
  }

  setTodayMap(item) {
    this.fileRecognitionCharge = this.keepTwoDecimal(item.file.recognitionCharge)
    this.fileRecognitionMinutes = this.keepTwoDecimal(item.file.recognitionMinutes)
    this.phraseRecognitionCharge = this.keepTwoDecimal(item.phrase.recognitionCharge)
    this.phraseUidCount = this.keepTwoDecimal(item.phrase.uidCount)
    this.fileUidCount = this.keepTwoDecimal(item.file.uidCount)
  }

  setTodayMap2(item) {
    this.recognitionCharge = item.recognitionCharge
    this.recognitionMinutes = item.recognitionMinutes
    this.recognitionSuccess = item.recognitionSuccess
    this.recognitionTotal = item.recognitionTotal
    this.uidCount = item.uidCount
  }

  setApiPrice(val) {
    this.apiPrice = val
  }

  setAsrPrice(val) {
    this.asrPrice = val
  }

  keepTwoDecimal(num) {
    var result = parseFloat(num);
    if (isNaN(result)) {
      return 0;
    }
    result = Math.round(num * 100) / 100;
    return result;
  }
}

class YesterdayMap {
  constructor(item, today) {
    this.fileRecognitionCharge = 0
    this.fileRecognitionMinutes = 0
    this.phraseRecognitionCharge = 0
    this.phraseUidCount = 0
  }

  setYesterdayMap(item, today) {
    this.fileRecognitionCharge = this.keepTwoDecimal(today.file.recognitionCharge - item.file.recognitionCharge)
    this.fileRecognitionMinutes = this.keepTwoDecimal(today.file.recognitionMinutes - item.file.recognitionMinutes)
    this.phraseRecognitionCharge = this.keepTwoDecimal(today.phrase.recognitionCharge - item.phrase.recognitionCharge)
    this.phraseUidCount = this.keepTwoDecimal(today.phrase.uidCount - item.phrase.uidCount)
  }

  keepTwoDecimal(num) {
    var result = parseFloat(num);
    if (isNaN(result)) {
      return 0;
    }
    result = Math.round(num * 100) / 100;
    return result;
  }
}
</script>
<style scoped>
.num {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sun {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>