<template>
  <div v-if="isAdminUser">
    <a-tabs default-active-key="1">
      <a-tab-pane key="1" tab="质检记录">
<!--        <api-rule :isAdminUser="isAdminUser"/>-->
        <ApiRecord :is-admin-user="isAdminUser=='system'"/>
      </a-tab-pane>
      <a-tab-pane key="2" tab="数据统计">
        <statistics :isAdminUser="isAdminUser"/>
      </a-tab-pane>
      <a-tab-pane key="3" tab="关键词配置">
        <KeywordList :is-admin-user="isAdminUser=='system'"/>
      </a-tab-pane>
      <a-tab-pane key="4" tab="话术匹配">
        <scriptMatching :is-admin-user="isAdminUser=='system'"/>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>
<script>

import _lineService from "@/api/open/LineQualityInspections";
// import interfaces from "@/views/open/ASRabilitys/interfaces.vue";
import statistics from "@/views/open/ASRabilitys/statistics.vue";
// import ApiRule from "@/views/open/ASRabilitys/api-rule.vue";
import ApiRecord from "@/views/open/apiQuality/record/ApiRecord.vue";
import KeywordList from "@/views/open/apiQuality/keyword/KeywordList.vue";
import scriptMatching from "@/views/open/apiQuality/keyword/scriptMatching.vue";
export default {
  components: {
    KeywordList,
    ApiRecord,
    // ApiRule,
    // interfaces,
    statistics,
    scriptMatching
  },
  data() {
    return {
      isAdminUser: '',
      // tabInit: {
      //   apiRule: true,
      //   statistics: false,
      // }
    };
  },
  created() {
    this.getUserType()
  },
  methods: {
    // tabChange(key) {
    //   if (key == 1 && !this.tabInit.apiRule) {
    //     this.tabInit.apiRule = true
    //   } else if (key == 2 && !this.tabInit.statistics) {
    //     this.tabInit.statistics = true
    //   }
    // },
    async getUserType() {
      const res = await _lineService._recordService.getUserType()
      this.isAdminUser = res?.data
    }
  },
};
</script>
<style lang="less" scoped>

</style>