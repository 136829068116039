<template>
  <a-row :gutter="12">
    <a-col :span="12">
      <div class="card-data" style="padding-left: 5px">
        <div style="padding: 15px 25px;background: #fff;border-radios: 4px;display: flex;
    box-shadow: 1px 1px 5px #999;">
          <div class="jin-a" style="width: 26%">
            <div class="num" style="font-size: 28px;font-weight: bold;color: #333333;line-height: 41px">
              <span>{{ todayMap.fileRecognitionCharge }}</span>
            </div>
            <div class="num-t" style="color: #B3B3B3;font-size: 14px;">
              <span>录音文件识别消费</span>
            </div>
          </div>
          <div class="zin-a" style="width: 13%;border-right: 1px solid #e4e4e4">
            <div class="sun" style="font-size: 18px;color: color: #787878;line-height: 41px;height: 41px;">
              <span style="color: #FF0000;font-size: 12px;">↑</span>
              <span>{{ yesterdayMap.fileRecognitionCharge }}</span>
            </div>
            <div class="num-t" style="color: #B3B3B3;font-size: 14px;">
              <span>较昨日</span>
            </div>
          </div>
          <div class="jin-a" style="width: 28%;padding-left:8px">
            <div class="num" style="font-size: 28px;font-weight: bold;color: #333333;line-height: 41px">
              <span>{{ todayMap.fileRecognitionMinutes ? (todayMap.fileRecognitionMinutes / 3600).toFixed(2) : 0 }}</span>
            </div>
            <div class="num-t" style="color: #B3B3B3;font-size: 14px;">
              <span>今日质检时长(小时)</span>
            </div>
          </div>
          <div class="zin-a" style="width: 13%;border-right: 1px solid #e4e4e4">
            <div class="sun" style="font-size: 18px;color: color: #787878;line-height: 41px;height: 41px;">
              <span style="color: #FF0000;font-size: 12px;">↑</span>
              <span>{{ yesterdayMap.fileRecognitionMinutes ? (yesterdayMap.fileRecognitionMinutes / 3600).toFixed(2) : 0 }}</span>
            </div>
            <div class="num-t" style="color: #B3B3B3;font-size: 14px;">
              <span>较昨日</span>
            </div>
          </div>
          <div class="jin-a" style="padding-left:8px;width: 25%">
            <div class="num" style="font-size: 28px;font-weight: bold;color: #333333;line-height: 41px">
              <span>{{ todayMap.fileUidCount }}</span>
            </div>
            <div class="num-t" style="color: #B3B3B3;font-size: 14px;">
              <span>用户数量</span>
            </div>
          </div>
        </div>
      </div>
    </a-col>
    <a-col :span="12">
      <div class="card-data" style="padding-right: 5px">
        <div style="padding: 15px 25px;background: #fff;border-radios: 4px;display: flex;
  box-shadow: 1px 1px 5px #999;">
          <div class="jin-a" style="width: 26%">
            <div class="num" style="font-size: 28px;font-weight: bold;color: #333333;line-height: 41px">
              <span>{{ todayMap.phraseRecognitionCharge }}</span>
            </div>
            <div class="num-t" style="color: #B3B3B3;font-size: 14px;">
              <span>一句话识别消费</span>
            </div>
          </div>
          <div class="zin-a" style="width: 12%;border-right: 1px solid #e4e4e4">
            <div class="sun" style="font-size: 18px;color: color: #787878;line-height: 41px;height: 41px;">
              <span style="color: #FF0000;font-size: 12px;">↑</span>
              <span>{{ yesterdayMap.phraseRecognitionCharge }}</span>
            </div>
            <div class="num-t" style="color: #B3B3B3;font-size: 14px;">
              <span>较昨日</span>
            </div>
          </div>
          <div class="jin-a" style="width: 28%;padding-left:8px;">
            <div class="num" style="font-size: 28px;font-weight: bold;color: #333333;line-height: 41px">
              <span>{{ todayMap.phraseUidCount }}</span>
            </div>
            <div class="num-t" style="color: #B3B3B3;font-size: 14px;">
              <span>今日调用总次数</span>
            </div>
          </div>
          <div class="zin-a" style="width: 12%;border-right: 1px solid #e4e4e4">
            <div class="sun" style="font-size: 18px;color: color: #787878;line-height: 41px;height: 41px;">
              <span style="color: #FF0000;font-size: 12px;">↑</span>
              <span>{{ yesterdayMap.phraseUidCount }}</span>
            </div>
            <div class="num-t" style="color: #B3B3B3;font-size: 14px;">
              <span>较昨日</span>
            </div>
          </div>
          <div class="jin-a" style="width: 22%;padding-left:8px;">
            <div class="num" style="font-size: 28px;font-weight: bold;color: #333333;line-height: 41px">
              <span>{{ todayMap.phraseUidCount }}</span>
            </div>
            <div class="num-t" style="color: #B3B3B3;font-size: 14px;">
              <span>用户数量</span>
            </div>
          </div>
        </div>
      </div>
    </a-col>
  </a-row>
</template>
<script>
export default {
  props: {
    todayMap: {
      type: Object,
      default: () => {
        return {}
      }
    },
    yesterdayMap: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
};
</script>